<template>
  <v-container id="pickups" fluid tag="section">
    <orders-view :orders="orders" :last-update="lastUpdate" :error="error" :no-result-title="$t('pickup.list.empty.title')" :no-result-description="$t('pickup.list.empty.description')"
                 mode="grouping" @refresh="refreshOrders"
    />
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import OrdersView from '@/views/components/business/order/OrdersView';

  export default {
    name: 'Pickup',
    components: { OrdersView },
    data () {
      return {
        error: '',
        lastUpdate: 0,
        orders: []
      };
    },
    methods: {
      async refreshOrders () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'orders/pickups/');
          this.orders = response.data;
          this.lastUpdate = Date.now();
          this.error = '';
        } catch (error) {
          this.error = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('pickup.error'));
        }
      }
    }
  };
</script>
